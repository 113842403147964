import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'gatsby';
import SEO from '../container/Seo/Seo';
import Layout from '../presentational/Layout/Layout';
import Banner from '../presentational/Banner/Banner';
import FormOverview from '../presentational/FormOverview/FormOverview';

function Submitted({ location }) {
  return (
    <Layout pathname={location.pathname}>
      <SEO title="Vielen Danke für Ihre Anfrage" />
      <Banner />

      <section className="section">
        <div className="container">
          <h1>Vielen Dank für Ihre Anfrage!</h1>
          <p>Wir schauen uns Ihre Wünsche an und setzen uns bald mit Ihnen in Verbindung.</p>
          <p>Falls noch etwas Zeit ist, würden wir uns sehr über eine <a target="_blank" rel="noreferrer" href="https://www.google.com/search?q=Sauer+-+Catering+%26+Ladenlokal+am+Sorpesee&sca_esv=583929409&rlz=1C5CHFA_enDE1024DE1024&ei=OTpbZfjqDrGD9u8PpLu44A4&ved=0ahUKEwi466-ptNKCAxWxgf0HHaQdDuwQ4dUDCBA&uact=5&oq=Sauer+-+Catering+%26+Ladenlokal+am+Sorpesee&gs_lp=Egxnd3Mtd2l6LXNlcnAiKVNhdWVyIC0gQ2F0ZXJpbmcgJiBMYWRlbmxva2FsIGFtIFNvcnBlc2VlMgYQABgWGB5IuwtQ5gRY5gRwAngBkAEAmAFroAFrqgEDMC4xuAEDyAEA-AEC-AEBwgIKEAAYRxjWBBiwA-IDBBgAIEGIBgGQBgg&sclient=gws-wiz-serp#lrd=0x47b95e9c51eb2e0d:0xe55094ca27e073a5,1,,,,">Google Bewertung</a> freuen.</p>
        </div>
      </section>

      <FormOverview submitted />
    </Layout>
  );
}

Submitted.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Submitted;
